import * as FullStory from '@fullstory/browser';

const isFullStoryEnabled = Boolean(import.meta.env.VITE_FULLSTORY_ORG)
    && import.meta.env.VITE_THIRD_PARTY_LOGGING === 'enabled';

export default {
    init(options) {
        if (isFullStoryEnabled) {
            FullStory.init(options);
        }
    },

    identify(id, identifyObj) {
        if (isFullStoryEnabled) {
            FullStory.identify(id, identifyObj);
        }
    },

    event(message, sourceObj) {
        if (isFullStoryEnabled) {
            FullStory.event(message, sourceObj);
        }
    },
};
