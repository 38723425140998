import axios from 'axios';
import sentry from '@/analytics/sentry';

export default {
    LOAD_ACCOUNTS(context, payload) {
        return loadAccounts(context, payload);
    },

    REMOVE_ACCOUNT(context, payload) {
        return removeAccount(context, payload);
    },
};


const loadAccounts = async ({ state, commit }, {
    pageSize = state.accounts.criteria.pageSize,
    searchText = state.accounts.criteria.searchText,
    orderBy = state.accounts.criteria.orderBy,
    pageNumber = state.accounts.pageNumber,
    reload = false,
} = {}) => {
    commit('LOAD_ACCOUNTS_START', {
        pageSize, searchText, orderBy, pageNumber, reload,
    });

    ({ pageNumber } = state.accounts);
    const pageToken = pageNumber >= 0 && pageNumber < state.accounts.pageTokens.length
        ? state.accounts.pageTokens[pageNumber]
        : null;

    try {
        const params = new URLSearchParams({
            expand: 'account',
            statuses: 'Active',
            limit: pageSize,
            userId: 'current',
            source: window.location.host, // so account-service can tell where the request comes from
        });
        let includesTotalCount = false;

        params.append('accountTypes', 'KEAP');
        params.append('accountTypes', 'CRM');

        if (pageToken) {
            params.append('cursor', pageToken);
        } else if (!searchText) {
            params.append('includeTotalCount', 'true');
            includesTotalCount = true;
        }

        if (searchText) {
            params.append('search', searchText);
        }

        orderBy.forEach((sort) => {
            params.append('sort', sort);
        });

        const url = `${import.meta.env.VITE_ACCOUNT_SERVICE_URL}/api/v2/userAccount?${params.toString()}`;
        const { data } = await axios.get(url);
        const { content: userAccounts, cursor: nextPageToken = null, totalCount: totalSize = null } = data;
        let accounts;

        if (Array.isArray(userAccounts) && userAccounts.length) {
            accounts = userAccounts.map(formatUserAccount);
        } else {
            accounts = [];
        }

        commit('LOAD_ACCOUNTS_SUCCESS', {
            accounts, nextPageToken, totalSize, searchText, includesTotalCount, pageNumber,
        });

        return accounts;
    } catch (error) {
        sentry.captureException(error);
        commit('LOAD_ACCOUNTS_ERROR', error);

        return {};
    }
};

const removeAccount = async ({ state, commit }) => {
    commit('REMOVE_ACCOUNT_START');

    try {
        const { name } = state.removal;

        const uri = `api/account/${name}`;

        await axios.delete(uri);

        commit('REMOVE_ACCOUNT_SUCCESS');
    } catch (error) {
        sentry.captureException(error);
        commit('REMOVE_ACCOUNT_FAILURE', error);

        return {};
    }

    return {};
};

const formatUserAccount = (account) => {
    const {
        account: {
            legacyId: tenantId,
            companyProfile: { name: companyName },
            edition: { name: editionName },
            keapWebUIActive,
            status: accountStatus,
        },
        name,
        status,
    } = account;

    const accountHref = keapWebUIActive
        ? `${import.meta.env.VITE_KEAP_URL}?app_id=${tenantId}`
        : `${import.meta.env.VITE_CORE_URL_PATTERN.replace('{id}', tenantId)}`;
    // all Keap-UI apps use the same tab for now, since keap-web does not support more than one app open at a time
    const target = keapWebUIActive ? `keap-web-${import.meta.env.VITE_ENV}` : `keap-${tenantId}-${import.meta.env.VITE_ENV}`;

    return {
        tenantId,
        name,
        companyName,
        editionName,
        href: accountHref,
        target,
        accountStatus,
        status,
    };
};
