import axios from 'axios';
import sentry from '@/analytics/sentry';

export default {
    GET_INVITE_DATA(context, payload) {
        return getInviteData(context, payload);
    },

    LINK_USER(context, payload) {
        return linkUser(context, payload);
    },
};

const getInviteData = async ({ commit }, {
    appName,
    inviteToken,
} = {}) => {
    commit('RETRIEVE_INVITE_DATA_START', {
        appName, inviteToken,
    });

    try {
        const tokenUrl = `${import.meta.env.VITE_ACCOUNT_SERVICE_URL}/api/v2/account/${appName}/invite`;
        const timeoutInMilli = 10000;

        const requestConfig = {};

        requestConfig.timeout = timeoutInMilli;

        axios.get(tokenUrl, requestConfig).then((response) => {
            if (response.data.valid) {
                const { company: companyName } = response.data;

                commit('RETRIEVE_INVITE_DATA_SUCCESS', { companyName });
            } else {
                commit('RETRIEVE_INVITE_DATA_FAILURE');
            }

            return {};
        }).catch((error) => {
            commit('RETRIEVE_INVITE_DATA_FAILURE');
            sentry.captureException(error);
        });

        return {};
    } catch (error) {
        commit('RETRIEVE_INVITE_DATA_FAILURE');
        sentry.captureException(error);

        return {};
    }
};

const linkUser = async ({ state, commit }, {
    appName = state.appName,
    inviteToken = state.inviteToken,
} = {}) => {
    commit('LINK_ACCOUNT_START', {
        appName, inviteToken,
    });

    try {
        const tokenUrl = `${import.meta.env.VITE_ACCOUNT_SERVICE_URL}/api/v2/account/${appName}/invite:accept`;
        const timeoutInMilli = 10000;

        const requestConfig = {};

        requestConfig.timeout = timeoutInMilli;

        axios.post(tokenUrl, {}, requestConfig).then((response) => {
            const appType = response.data.app_type;

            commit('LINK_ACCOUNT_SUCCESS', { appType });
        }).catch((error) => {
            commit('LINK_ACCOUNT_FAILURE', error);
            sentry.captureException(error);
        });

        return {};
    } catch (error) {
        commit('LINK_ACCOUNT_FAILURE', error);
        sentry.captureException(error);

        return {};
    }
};
