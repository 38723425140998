/**
 * Vuex 'context' for actions are found here: https://vuex.vuejs.org/api/#actions
 */

import amplitude from '@/analytics/amplitude';
import { AMPLITUDE_EVENTS } from '@/analytics/constants/amplitude-events.constants';
import axios from 'axios';
import humps from 'lodash-humps';
import {
    LOADING,
    SUCCESS,
    ERROR,
} from '@/shared/constants/loadingStatuses.constants';
import sentry from '@/analytics/sentry';

const AC_BFF_URL = import.meta.env.VITE_ACCOUNT_CENTRAL_BFF_URL;

export default {
    CHARGEBEE_INITIALIZED({ commit }) {
        commit('CHARGEBEE_INITIALIZED');
    },

    LOAD_SUBSCRIPTION_INFO(context, payload) {
        return loadSubscriptionInfo(context, payload);
    },

    LOAD_RENEWAL_ESTIMATE(context, payload) {
        return loadRenewalEstimate(context, payload);
    },

    REMOVE_SCHEDULED_CHANGES(context, payload) {
        removeScheduledChanges(context, payload);
    },

    REMOVE_SCHEDULED_CANCELLATION(context, payload) {
        removeScheduledCancellation(context, payload);
    },

    LOAD_USAGES(context, payload) {
        return loadUsages(context, payload);
    },

    LOAD_HAS_MANAGE_BILLING_PERMISSION(context, payload) {
        return loadManageBillingPermission(context, payload);
    },

    LOAD_ADDONS_PRICING(context, payload) {
        return loadAddonsPricing(context, payload);
    },
};

const loadSubscriptionInfo = async (context, { tenantId }) => {
    const { commit } = context;
    try {
        sentry.captureException(new Error(`Test sentry errors ${tenantId}`));
        commit('SET_SUBSCRIPTION_INFO_LOADING', LOADING);
        const loadSubscriptionInfoPath = `${AC_BFF_URL}/v1/tenants/${tenantId}/subscription-info`;

        const { data } = await axios.get(loadSubscriptionInfoPath);

        if (data) {
            commit('SET_SUBSCRIPTION_INFO', humps(data));
            commit('SET_SUBSCRIPTION_INFO_LOADING', SUCCESS);
        } else {
            commit('SET_SUBSCRIPTION_INFO_LOADING', ERROR);
        }
    } catch (e) {
        sentry.captureException(e, { message: `Error fetching subscription info for: ${tenantId}` });
        commit('SET_SUBSCRIPTION_INFO_LOADING', ERROR);
    }
};

const loadRenewalEstimate = async (context, { tenantId }) => {
    const { commit } = context;
    try {
        commit('SET_RENEWAL_ESTIMATE_LOADING', LOADING);
        const loadRenewalEstimatePath = `${AC_BFF_URL}/v1/tenants/${tenantId}/renewal-estimate`;

        const { data } = await axios.get(loadRenewalEstimatePath);

        if (data) {
            commit('SET_RENEWAL_ESTIMATE', humps(data));
            commit('SET_RENEWAL_ESTIMATE_LOADING', SUCCESS);
        } else {
            sentry.captureException(new Error(`Error fetching renewal estimate, empty body returned for ${tenantId}`));
            commit('SET_RENEWAL_ESTIMATE_LOADING', ERROR);
        }
    } catch (e) {
        sentry.captureException(e, { message: `Error fetching renewal estimate, http exception for ${tenantId}` });
        commit('SET_RENEWAL_ESTIMATE_LOADING', ERROR);
    }
};

const removeScheduledChanges = async (context, { tenantId }) => {
    const { commit } = context;
    commit('SET_CANCEL_SCHEDULED_CHANGES_LOADING', LOADING);

    try {
        const removeScheduledChangesPath = `${AC_BFF_URL}/v1/tenants/${tenantId}/subscription-scheduled-changes`;

        const { status } = await axios.delete(removeScheduledChangesPath);

        if (status === 204) {
            commit('SET_CANCEL_SCHEDULED_CHANGES_LOADING', SUCCESS);
            amplitude.logEvent(AMPLITUDE_EVENTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_SUCCESS, {
                'Event Source': 'Subscription Details Modal',
            });
        } else {
            sentry.captureException(new Error(`Error removing scheduled changes, non 204 status returned for ${tenantId}`));
            commit('SET_CANCEL_SCHEDULED_CHANGES_LOADING', ERROR);
            amplitude.logEvent(AMPLITUDE_EVENTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_FAILURE, {
                'Event Source': 'Subscription Details Modal',
            });
        }
    } catch (e) {
        sentry.captureException(e, { message: `Error removing scheduled changes, http exception for ${tenantId}` });
        commit('SET_CANCEL_SCHEDULED_CHANGES_LOADING', ERROR);
        amplitude.logEvent(AMPLITUDE_EVENTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_FAILURE, {
            'Event Source': 'Subscription Details Modal',
        });
    }
};

const removeScheduledCancellation = async (context, { tenantId }) => {
    const { commit } = context;
    commit('SET_REMOVE_SCHEDULED_CANCELLATION_LOADING', LOADING);

    try {
        const removeScheduledCancellationPath = `${AC_BFF_URL}/v1/tenants/${tenantId}/scheduled-cancellation`;

        const { status } = await axios.delete(removeScheduledCancellationPath);

        if (status === 204) {
            commit('SET_REMOVE_SCHEDULED_CANCELLATION_LOADING', SUCCESS);
        } else {
            sentry.captureException(new Error(`Error removing scheduled cancellation, non 204 status returned for ${tenantId}. Status: ${status}`));
            commit('SET_REMOVE_SCHEDULED_CANCELLATION_LOADING', ERROR);
        }
    } catch (e) {
        sentry.captureException(e, { message: `Error removing scheduled cancellation, http exception for ${tenantId}` });
        commit('SET_REMOVE_SCHEDULED_CANCELLATION_LOADING', ERROR);
    }
};

const loadUsages = async (context, { tenantId }) => {
    const { commit } = context;
    commit('SET_USAGES_LOADING', LOADING);

    try {
        const loadUsagesPath = `${AC_BFF_URL}/v1/usage/${tenantId}/usages`;

        const { data } = await axios.get(loadUsagesPath);

        if (data) {
            commit('SET_USAGES', humps(data));
            commit('SET_USAGES_LOADING', SUCCESS);
        } else {
            sentry.captureException(new Error(`Error loading usages, empty body returned for ${tenantId}`));
            commit('SET_USAGES_LOADING', ERROR);
        }
    } catch (e) {
        sentry.captureException(e, { message: `Error loading usages, http exception for ${tenantId}` });
        commit('SET_USAGES_LOADING', ERROR);
    }
};

const loadManageBillingPermission = async (context, { tenantId }) => {
    const { commit } = context;
    commit('SET_MANAGE_BILLING_PERMISSION_LOADING', LOADING);

    try {
        const loadUsagesPath = `${AC_BFF_URL}/v1/tenants/${tenantId}/hasManageBillingPermission`;

        await axios.get(loadUsagesPath);

        commit('SET_HAS_MANAGE_BILLING', true);
        commit('SET_MANAGE_BILLING_PERMISSION_LOADING', SUCCESS);
    } catch (e) {
        if (e.response && e.response.status === 403) {
            // 403 Forbidden means the user does not have permission to manage billing
            commit('SET_HAS_MANAGE_BILLING', false);
            commit('SET_MANAGE_BILLING_PERMISSION_LOADING', SUCCESS);
            return;
        }

        sentry.captureException(e, { message: `Error loading usages, http exception for ${tenantId}` });
        commit('SET_HAS_MANAGE_BILLING', false);
        commit('SET_MANAGE_BILLING_PERMISSION_LOADING', ERROR);
    }
};

const loadAddonsPricing = async (context, { tenantId }) => {
    const { commit } = context;
    try {
        commit('SET_ADDONS_PRICING_LOADING', LOADING);

        const loadSubscriptionInfoPath = `${AC_BFF_URL}/v1/prices/${tenantId}/editable-addons`;

        const { data } = await axios.get(loadSubscriptionInfoPath);

        if (data) {
            commit('SET_ADDONS_PRICING', humps(data));
            commit('SET_ADDONS_PRICING_LOADING', SUCCESS);
        } else {
            commit('SET_ADDONS_PRICING_LOADING', ERROR);
        }
    } catch (e) {
        sentry.captureException(e, { message: `Error fetching addon pricing for: ${tenantId}` });
        commit('SET_ADDONS_PRICING_LOADING', ERROR);
    }
};
