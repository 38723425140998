import { createApp } from 'vue';
import { createDesignSystem } from '@infusionsoft/design-system';

import sentry from './analytics/sentry';
import fullstory from './analytics/fullstory';
import App from './App.vue';
import { auth } from './plugins/auth.js';
import { createRouter } from './router';
import { createStore } from './store';
import { i18n } from './i18n';
import performance from './shared/utils/performance.util';
import { handlePromiseRejections, handleError } from './shared/utils/error.util';
import { setupInterceptors } from './shared/utils/interceptor.util';
import { components, icons, illustrations } from './shared/utils/design-system-imports.util';
import './shared/styles/main.scss';

const app = createApp(App);
const router = createRouter();
const store = createStore();
const designSystem = createDesignSystem({
    components,
    icons,
    illustrations,
    useNewIllustrations: true,
});

fullstory.init({
    orgId: import.meta.env.VITE_FULLSTORY_ORG,
    devMode: import.meta.env.NODE_ENV !== 'production',
});

app.use(designSystem);
app.use(router);
app.use(auth);
app.use(store);
app.use(i18n);

setupInterceptors(store);

app.config.globalProperties.$number = i18n.global.n;

app.config.errorHandler = handleError;
handlePromiseRejections();

if (import.meta.env.NODE_ENV !== 'development') {
    sentry.init(app);
}

app.config.globalProperties.$log = sentry.log;
app.config.globalProperties.$pagePerformance = performance.pagePerformance;

app.mount('#app');
