import axios from 'axios';
import sentry from '@/analytics/sentry';

export default {
    LOAD_CLIENT_INFO(context, payload) {
        return loadClientInformation(context, payload);
    },

    GET_AUTH_CODE(context, payload) {
        return getAuthorizationCode(context, payload);
    },
};

const loadClientInformation = async ({ commit }, { clientId }) => {
    try {
        commit('LOAD_CLIENT_INFO_START');

        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/v1/authorize/client/${clientId}`;

        const response = await axios.get(url);

        if (response && response.status === 200) {
            const { clientName, description } = response.data;

            if (clientName) {
                commit('LOAD_CLIENT_INFO_SUCCESS', { clientName, description });
            } else {
                commit('LOAD_CLIENT_INFO_ERROR', 'Error occurred when fetching client information from Apigee');
            }
        } else {
            commit('LOAD_CLIENT_INFO_ERROR', 'Error occurred when fetching client information from Apigee');
        }
    } catch (error) {
        sentry.captureException(error);
        commit('LOAD_CLIENT_INFO_ERROR', error.message || 'An error occurred when fetching client information from Apigee');
    }
};

const getAuthorizationCode = async ({ commit }, {
    clientId, redirectUrl, scope, appName, state,
}) => {
    commit('SET_REDIRECT_URI', { redirectUrl });
    commit('GET_AUTH_CODE_START');
    const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/v1/authorize/client`;

    const requestBody = {
        clientId,
        redirectUri: redirectUrl,
        scope,
        application: appName,
        state,
    };

    await axios.post(url, requestBody, { timeout: 5000 })
        .then((response) => {
            if (response && response.status === 200) {
                const { redirectUri } = response.data;

                commit('GET_AUTH_CODE_SUCCESS', { redirectUri });
            }

            return response;
        }).catch((error) => {
            sentry.captureException(error);
            const code = error.response?.data?.code || 'unknown_error';
            const message = error.response?.data?.message || 'Error fetching authorization code from Apigee';

            commit('GET_AUTH_CODE_ERROR', { code, message });
        });
};
