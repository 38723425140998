<template>
    <div class="root">
        <AppPlaceholder v-if="isRequestProcessing" />

        <main v-else-if="hideNavbar" class="scroll-container public" data-qa="public-content">
            <router-view />
        </main>

        <div v-else-if="bootLoadError" class="page-overlay">
            <BootLoadError />
        </div>

        <template v-else-if="bootLoadSuccess">
            <main class="scroll-container" data-qa="content">
                <NavHeader />
                <router-view />
            </main>

            <AnalyticsHandler />
            <DsFocusOnKeyboardOnly />
            <DsToastContainer />
        </template>
    </div>
</template>

<script>

import { mapState } from 'vuex';

import { DsFocusOnKeyboardOnly, DsToastContainer } from '@infusionsoft/design-system';
import AppPlaceholder from '@/components/AppPlaceholder.vue';
import AnalyticsHandler from '@/analytics/components/AnalyticsHandler.vue';
import BootLoadError from '@/components/BootLoadError.vue';
import NavHeader from '@/components/NavHeader.vue';
import { ERROR, SUCCESS } from '@/shared/constants/loadingStatuses.constants';
import { useAuth0 } from '@auth0/auth0-vue';


export default {
    components: {
        AppPlaceholder,
        AnalyticsHandler,
        BootLoadError,
        NavHeader,
        DsFocusOnKeyboardOnly,
        DsToastContainer,
    },

    setup() {
        const auth = useAuth0();

        return { auth };
    },

    data() {
        return {
            isAuthenticated: false,
        };
    },

    mounted() {
        this.isAuthenticated = this.auth.isAuthenticated;
    },

    watch: {

        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.$store.dispatch('global/LOAD_BOOT');
            }
        },

    },

    computed: {
        ...mapState({
            bootStatus: ({ global }) => global.boot.status,
        }),

        isRequestProcessing() {
            return !this.$route.meta?.title;
        },

        bootLoadSuccess() {
            return this.bootStatus === SUCCESS;
        },

        bootLoadError() {
            return !this.auth.isAuthenticated.value || this.bootStatus === ERROR;
        },

        hideNavbar() {
            return Boolean(this.$route.meta?.isPublic) || Boolean(this.$route.meta?.isInvite) || Boolean(this.$route.meta?.isOAuth) || Boolean(this.$route.meta?.hideNavbar);
        },
    },
};
</script>

<style lang="scss" scoped>
.root {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-paper;
}

.scroll-container {
    @include overflowX;
    @include overflowY;
    flex: auto;
    width: 100%;
    max-width: 100%;
    position: relative;
}

.page-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
