import { loadFeatureFlags } from './featureFlags.js';
import { loadLocale } from '@/i18n';
import sentry from '@/analytics/sentry';
import { SUCCESS } from '@/shared/constants/loadingStatuses.constants';

export default {
    LOAD_BOOT(context, payload) {
        return loadBoot(context, payload);
    },
};

const loadBoot = async ({ state, commit }, { reload = false } = {}) => {
    if (state.boot.status === SUCCESS && !reload) {
        return;
    }

    commit('LOAD_BOOT_START');

    try {
        const [featureFlags, locale] = await Promise.all([
            await loadFeatureFlags(),
            await loadLocale(),
        ]);

        commit('LOAD_BOOT_SUCCESS', { locale, featureFlags });
    } catch (e) {
        sentry.captureException(e);
        commit('LOAD_BOOT_ERROR', e);
    }
};
