<script>
import { mapState } from 'vuex';

import sentry from '@/analytics/sentry';
import fullstory from '@/analytics/fullstory';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
    setup() {
        const auth = useAuth0();

        return { auth };
    },

    data() {
        return {
            user: null,
            isAuthenticated: false,
        };
    },

    mounted() {
        this.user = this.auth.user;
        this.isAuthenticated = this.auth.isAuthenticated;
        this.init();
    },
    computed: {
        ...mapState({
            featureFlags: ({ global }) => global.featureFlags,
        }),

        analyticsConfigData() {
            const featureFlags = this.featureFlags || {};
            return {
                userId: this.user?.sub,
                userDomain: this.userDomain,
                featureFlags: this.featureFlags && Object.keys(this.featureFlags).filter((key) => featureFlags[key] === true),
            };
        },

        userDomain() {
            return this.user?.email?.split('@')[1];
        },
    },

    methods: {
        init() {
            if (this.isAuthenticated) {
                sentry.setUser(this.analyticsConfigData);
                fullstory.identify(this.user?.sub, {
                    email: this.user?.email,
                    firstName: this.user?.given_name,
                    lastName: this.user?.family_name,
                    environment: import.meta.env.VITE_ENV,
                });
            }
        },
    },
};
</script>

<template>
    <div />
</template>
